@import './../../variables.scss';
.QtySelector {
    .QtyInput {
        width: 40px;
        text-align: center;
        border: 1px solid black;
        height: 30px;
        font-weight: bold;
    }

    button {
        width: 95px;
    }
    
    .AddBtn { 
        margin-left: 15px; 
        background-color: $primary;
    }
    .RemoveBtn {
        background-color: $secondary;
        margin-top: 5px;
    }
}