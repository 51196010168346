.Contacto {
    margin-bottom: -35px;
    .container {
        h3 {
            margin-top:40px;
        }
        .ContactoContent {
            margin: 40px auto 60px auto;
        }
        .MsgContainer {
            height: 100%;
            padding-bottom: 13px;
            textarea.form-control {
                height: 100%;
            }
        }

        .SendBtnContainer {
            margin-top: 20px;
            text-align: right;
        }
    }
}