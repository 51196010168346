@import './../../variables.scss';

.AuthNavContainer {
    background-color: $primary;

    .subtitle {
        color: white;
        float: left;
        font-weight: 600;
        font-size: 14px;
        margin-top: 17px;
    }

    .container { overflow: hidden ;}
    ul.AuthNavLinks {
        margin: 10px 0;
        float: right;

        li {
            padding: 5px 20px 5px 0;

            a { 
                color: white;
                font-size: 13px;

                &.active-route { font-weight: 600; }
            }

            &:last-child { 
                border-left: 2px solid $primary-light;
                padding: 5px 0 5px 20px;
            }
        }
    }
}

@media (max-width: 580px) {
    ul.AuthNavLinks {
        float: right;
        text-align: center;
        width: 100%;
        li {
            display: inherit;
            padding: 10px 0!important;

            &:last-child { border-left: none!important; }
        }
    }

    .AuthNavContainer .subtitle {
        float: none!important;
        text-align: center;
    }
}