@import './../../variables.scss';

.CartItemContainer {
    border-bottom: 2px solid $tertiary-gray;
    font-weight: 600;
    overflow: hidden;

    .ItemName {
        float: left;
        margin: 18px 0;
    }

    .QtyHandler {
        float: right;

        span { margin: 10px; }

        .TotalItem {
            display: inline-block;
            width: 70px;
        }

        .QtySelector {
            display: inline-block;
            margin: 15px;
        }

        .removeIcon {
            background-repeat: no-repeat;
            background-image: url('./trash.jpg');
            background-size: 19px 25px;
            cursor: pointer;
            display: inline-block;
            height: 25px;
            width: 19px;
            vertical-align: middle;
        }
    }
}