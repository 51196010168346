ul.NavList {
    display: table-row;
    padding: 0;
    margin: 0;

    li { 
        display: table-cell; 
        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
    }
}