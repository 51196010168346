@import './../../variables.scss';

.SearchContainer {
    position: relative;

    .SearchInput {
        border: none;
        border-bottom: 1px solid $primary;
        color: $primary;
        padding: 0 0 10px 10px;
        width: 100%;
    }

    .searchIcon {
        background-repeat: no-repeat;
        background-image: url('./search.png');
        background-size: 25px;
        display: inline-block;
        height: 25px;
        width: 25px;
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}

@media (max-width: 9920px) {
    .SearchInput { margin-top: 20px; }
}