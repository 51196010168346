@import './../../variables.scss';

.ProductRow {
    font-weight: 600;
    border-bottom: 2px solid $tertiary-gray;
    p {
        font-size: 13px;
        min-height: 45px;
        text-align: center;
    }

    img { width: 80px; }

    td { 
        padding: 15px; 
        vertical-align: -webkit-baseline-middle;
        &:first-child { padding-left: 0; }
        &:last-child { padding-right: 0; }
    }

    .Desart {
        color: $tertiary;
        p { text-align: left; }
    }

    .Categorias,
    .Codart,
    .Price { color: $primary; }

    .QtyCell {
        text-align: right;
        width: 25%;
    }
}

@media (max-width: 580px) {
    .ProductRow {
        thead {
            th {
                padding: 10px 0;
                font-size: 11px;
            }
        }

        td {
            padding: 15px 5px;
            p {
                font-size: 11px;
                font-weight: lighter;
            }

            img { 
                width: 50px!important;
                height: 40px!important; 
            }
        }
    }
}