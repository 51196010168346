@import './../../variables.scss';
.ProductList {
    overflow: auto;
    font-size: 14px;
    
    table { width: 100%; }
    thead {
        background-color: $primary;
        color: white;
        text-align: center;
        th { padding: 10px; }
    }

    .pagination {
        float: right;
        margin: 30px 0;

        .ActivePage,
        .PageButton {
            border-radius: 0;
            color: $primary;
            background-color: white;
            border-color: $primary;
            margin-right: -1px;

            &:last-child { margin-right: 0; }
        }

        .ActivePage {
            color: white;
            background-color: $primary;
        }
    }
}

.ProductGetStarted {
    margin: 100px auto;
    font-size: 21px;
    text-align: center;
    width: 300px;

    .separator { margin: auto; }
}
