@import './../../variables.scss';
.PedidoItem {
    background-color: $primary-gray;
    padding: 20px;
    border-left: 5px solid $tertiary-gray;
    width: 70%;
    margin: 20px 0;
    border-bottom-right-radius: 10px;

    .DateLabel,
    .DateValue,
    .SuCompra { color: $primary; }

    .DateLabel { 
        font-size: 14px;
        margin-bottom: 0;
    }
    .DateValue {
        font-size: 18px;
        font-weight: 600;
    }

    ul {
        list-style: disc;
        padding-left: 15px;
        color: black;
        font-weight: 600;
    }

    .TotalPedido {
        color: $primary;
        text-align: right;
        span { font-weight: 600; }
    }
}
@media (max-width: 993px) {
    .PedidoItem { width: 100%; }
}