@import './../../variables.scss';

.SpinnerContainer {
    margin: 50px;

    h3 { color: $primary-dark; }
}

@media (max-width: 993px) {
    .SpinnerContainer {
        padding: 50px 0;
    }
}