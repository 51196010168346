@import './../../variables.scss';
.Slider {
    .SliderCaption {
        background-color: $tertiary;
        background: rgba(35, 48, 74, 0.9);
        width: 450px;
        border-bottom-right-radius: 50px;
        font-size: 24px;
        margin-bottom: 50px;
        padding: 30px;
        min-height: 180px;
        text-align: left;
        right: 10%;
        left: auto;
        p { margin: 10px 0; }
    }
}

.SliderBar {
    height: 10px;
    overflow: hidden;

    .SliderBlueBar,
    .SliderYellowBar { 
        float: left;
        height: 100%;
        width: 50%;
    }
    .SliderBlueBar { background-color: $primary; }
    .SliderYellowBar { background-color: $secondary; }
}