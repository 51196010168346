@import './../../variables.scss';
.Mag-Input {
    &:focus {
        border: 2px solid $primary;
        box-shadow: none;
        outline: none;
    }

    &::-webkit-input-placeholder { color:$primary; }
    &:-ms-input-placeholder { color: $primary; }
    &::placeholder { color: $primary; }
}