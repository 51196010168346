@import './../../variables.scss';
.Checkout {
    margin-bottom: 70px;
    .CartTotal {
        color: $primary;
        font-size: 21px;
        text-align: right;
        margin: 20px 0;
    }

    .Observaciones {
        border-radius: 3px;
        border: 1px solid gray;
        height: 200px;
        padding: 10px 15px;
        margin-bottom: 15px;
        width: 100%;
    }

    .TomarPedidoBtn {
        margin-bottom: 20px;
    }
}