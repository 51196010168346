@import './../../variables.scss';

.FooterContainer {
    position: absolute;
    bottom: 0;
    width: 100%;
    
    .MainFooterContainer {
        background-color: $primary;
        color: white;
        padding: 30px 0;

        .FooterLogoContainer {
            text-align: right;
        }

        p { 
            font-size: 14px;
            margin-bottom: 15px; 
        }

        a { 
            color: white;
            font-weight: 600;
            margin-left: 10px;
        }

        .SmallIcon {
            background-repeat: no-repeat;
            background-size: 25px;
            display: inline-block;
            height: 25px;
            vertical-align: middle;
            width: 25px;

            &.FooterLogo {
                background-image: url('./icons/logo-footer.png');
                background-size: 128px 100px;
                height: 100px;
                width: 128px;
            }
            
            &.EmailIcon { background-image: url('./icons/email.png'); }
            &.PhoneIcon { background-image: url('./icons/phone.png'); }
            &.WpIcon { background-image: url('./icons/wp.png'); }
            &.GpsIcon { background-image: url('./icons/gps.png'); }
            &.TimeIcon { background-image: url('./icons/time.png'); }
        }

        .SocialMediaContainer .SmallIcon {
            background-size: 50px;
            height: 50px;
            width: 50px;

            &:hover {
                background-position-x: 0;
                background-position-y: -50px;
                cursor: pointer;
            }
        }

        .DataFiscalIcon { 
            width: 50px;
            position: absolute;
            bottom: 20px;
            right: 15px;
        }
    }

    .SubfooterContainer {
        background-color: #062234;
        color: white;
        font-size: 12px;
        padding: 15px 0;

        a {
            color: $primary-light;
            .husky-logo {
                background-image: url('./icons/husky.png');
                background-repeat: no-repeat;
                background-size: 100px 15px;
                display: inline-block;
                height: 15px;
                margin-left: 5px;
                vertical-align: middle;
                width: 100px;
            }
        }
    }
}

@media (max-width: 576px) {
    .FooterLogoContainer,
    .MainFooterContainer,
    .SubfooterContainer {
        text-align: center!important;
        
    }
}