.ProductsContainer {
    padding: 20px 0;
    overflow: hidden;

    .FamiliasContainer {
        backface-visibility: hidden;
        display: flex;
        float: left;
        height: 100vh;
        width: 25%;
        will-change: overflow;

        .FamiliaList {
            height: auto;
            overflow: auto;
            width: 100%;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
        }
    }

    .ProductListContainer { 
        padding-left: 20px;
        float: left;
        width: 75%;
    }

    .ProductFilterContainer { margin-bottom: 20px; }

    @media (max-width: 993px) {
        display: initial;
        .ProductListContainer,
        .FamiliasContainer { 
            float: none;
            margin: 15px 0;
            height: auto;
            width: 100%; 
        }
        .FamiliaList {
            width: 100%;
        }

        .ProductListContainer { 
            padding-left: 0; 
            margin-bottom: 50px;
            button { margin-top: 10px;}
        }
    }
}

.ProductsError { margin-bottom: 30px; }

.UltimaModificacion {
    float: right;
    margin-top: 20px;
}